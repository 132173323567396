import React from 'react'
import Button from 'components/globals/button'

export default ({
    sideImage,
    items
}) => {    
    return (
        <div className={`background-container ${ !sideImage ? 'no-side-image' : '' }`}>
            { sideImage &&
                <img src={sideImage.mediaItemUrl} className={'bg'} alt="Afterpay" />
            }
            <div className="container">
                <ul className="grid">
                    {items.map((item, index) => {
                        return (
                            <li 
                                key={index}
                                style={{
                                    transitionDelay: (index/10)+'s'
                                }}
                            >
                                <p className="title bold">
                                    {item.title}
                                </p>
                                <div className="copy" dangerouslySetInnerHTML={{__html: item.copy}} />
                                { item.button &&
                                    <div className={'button-row'}>
                                        <Button
                                            label={item.button.title}
                                            url={item.button.url}
                                            green
                                        />
                                    </div>
                                }
                            </li>
                        )
                    })}
                </ul>
            </div>
        </div>
    )
}
