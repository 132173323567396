import React from "react"
import { graphql } from "gatsby"

import FlexibleContent from "components/globals/flexibleContent"

import PageWrapper from 'components/globals/pageWrapper'

export default ({ data }) => {
	const {
		databaseId,
		slug,
		title,
		excerpt,
		flexibleContent,
	} = data.siteData.page

	return (
		<>
			<PageWrapper
				title={title}
				description={excerpt}
			>
				<FlexibleContent
					id={databaseId}
					content={flexibleContent}
					slug={slug}
				/>
			</PageWrapper>
		</>
	)
}

export const query = graphql`
	query($databaseId: ID!) {
		siteData {
			page(id: $databaseId, idType: DATABASE_ID) {
				databaseId
				title
				slug
				date
				content(format: RENDERED)
				flexibleContent {
					flexibleContent {
						__typename
						... on WPGraphQL_Page_Flexiblecontent_FlexibleContent_Hero {
							heading
							copy
							video {
								mediaItemUrl
							}
							backgroundVideo {
								mediaItemUrl
							}
						}
						... on WPGraphQL_Page_Flexiblecontent_FlexibleContent_SimpleHero {
							heading
							backgroundImage {
								mediaItemUrl
							}
						}
						... on WPGraphQL_Page_Flexiblecontent_FlexibleContent_BigParagraph {
							copy
							alignment
							hashtag
						}
						... on WPGraphQL_Page_Flexiblecontent_FlexibleContent_TitleCopy {
							title
							copy
							hashtag
						}
						... on WPGraphQL_Page_Flexiblecontent_FlexibleContent_SoundbitesCarousel {
							backgroundImage {
								mediaItemUrl
							}
							items {
								quote
								attribution {
									name
									role
								}
								backgroundColour
								backgroundImage {
									mediaItemUrl
								}
								soundbite {
									databaseId
									mediaItemUrl
								}
							}
						}
						... on WPGraphQL_Page_Flexiblecontent_FlexibleContent_StatsGrid {
							items {
								type
								figure
								copy
								backgroundColour
								image {
									mediaItemUrl
								}
							}
						}
						... on WPGraphQL_Page_Flexiblecontent_FlexibleContent_SimpleImageWithCopy {
							title
							copy
							images {
								image {
									mediaItemUrl
								}
							}
						}
						... on WPGraphQL_Page_Flexiblecontent_FlexibleContent_SimplePointsGrid {
							sideImage {
								mediaItemUrl
							}
							items {
								title
								copy
								button {
									title
									url
								}
							}
						}
						... on WPGraphQL_Page_Flexiblecontent_FlexibleContent_VideoHero {
							title
							backgroundVideo {
								mediaItemUrl
							}
							video {
								mediaItemUrl
							}
						}
						... on WPGraphQL_Page_Flexiblecontent_FlexibleContent_BlogCarousel {
							unusedField
						}
						... on WPGraphQL_Page_Flexiblecontent_FlexibleContent_AlternatingTeamsGrid {
							items {
								questions {
									question
									answer
								}
								button {
									title
									url
								}
								person {
									name
									role
									linkedinUrl
									photo {
										mediaItemUrl
									}
									backgroundColour
								}
							}
						}
					}
				}
			}
		}
	}
`
