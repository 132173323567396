import React from 'react'

export default ({
    items,
}) => {
    if(!items){
        return null
    }

    return (
        <div className="container">
            <div className="stats">
                {items.map((item, index) => {
                    const {
                        image,
                        copy,
                        figure,
                        backgroundColour
                    } = item

                    let className = 'stat' 
                    className += ' '+backgroundColour
                    
                    if(image) {
                        className += ' has-image' 
                    }

                    if(!image && !figure && !copy){
                        className += ' is-empty'
                    }

                    return (
                        <div 
                            className={className} 
                            key={index}
                            style={{
                                transitionDelay: (index/10)+'s'
                            }}
                        >
                            <div className={'inner'}>
                                {figure && 
                                    <h2 className="h1">
                                        <span>
                                            { figure }
                                        </span>
                                    </h2>
                                }
                                {copy &&
                                    <div dangerouslySetInnerHTML={{__html: copy}} />
                                }
                                {image &&
                                    <img src={image.mediaItemUrl} alt={ figure } />
                                }
                            </div>
                        </div>
                    )
                })}
            </div>
        </div>
    )
}