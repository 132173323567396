import React from 'react'

import Button from 'components/globals/button'
import LinkedInIcon from 'images/linkedin-icon.png'

export default ({
    items
}) => {
    return (
        <div className={'container'}>
            <ul className={'alternating-teams-grid-list'}>
                { items.map((item, index) => {
                    const {
                        questions,
                        button,
                        person: {
                            backgroundColour,
                            linkedinUrl,
                            name,
                            role,
                            photo
                        }
                    } = item

                    return (
                        <li key={index} style={{ transitionDelay: (index/10)+'s' }}>
                            <div className={`box ${ backgroundColour }`}>
                                <div className="inner">
                                    <h2>
                                        { name }
                                    </h2>
                                    <span className="role">
                                        { role }
                                    </span>
                                    <div className="linkedin">
                                        <a href={linkedinUrl}>
                                            <span>
                                                Connect with me on LinkedIn:
                                            </span>
                                            <img src={LinkedInIcon} alt="Connect with me on LinkedIn" />
                                        </a>
                                    </div>
                                </div>
                                <div className="photo" style={{
                                    backgroundImage: `url(${ photo.mediaItemUrl })`
                                }} />
                            </div>
                            <div className="questions">
                                <ul>
                                    { questions.map((question, questionIndex) => {
                                        return (
                                            <li key={questionIndex}>
                                                <span className="question" dangerouslySetInnerHTML={{ __html: question.question }} />
                                                <div className="copy medium" dangerouslySetInnerHTML={{ __html: question.answer }} />
                                            </li>
                                        )
                                    })}
                                </ul>
                                <div className={'button-row'}>
                                    <Button
                                        label={button.title}
                                        url={button.url}
                                        green
                                    />
                                </div>
                            </div>
                        </li>
                    )
                })}
            </ul>
        </div>
    )
}
