import React, { useRef, useState } from 'react'
import PlayIcon from 'images/play-icon.png'
import Loader from 'components/globals/loader'

import { useIsMobile } from 'utils'

export default ({
    item,
    playAudio,
    stopAudio,
}) => {
    const canvasRefs = useRef([])
    const timeoutRef = useRef(null)
    const isPlayingRef = useRef(false)
    const pausedTimeRef = useRef(0)

    const {
        soundbite,
        backgroundColour,
        backgroundImage,
        attribution,
        quote
    } = item

    const isMobile = useIsMobile()

    const handleMouseEnter = () => {
        if(typeof navigator === 'undefined'){
            return
        }

        const ua = navigator.userAgent.toLowerCase()

        if ( /^((?!chrome|android).)*safari/i.test(ua)) {
            return
        }

        if(!timeoutRef.current && !isMobile){
            stopAudio()
            
            timeoutRef.current = setTimeout(() => {
                isPlayingRef.current = true
                playAudio(soundbite, canvasRefs.current)
            },200)
        }
    }

    const handleStop = callback => {
        isPlayingRef.current = false
        if(timeoutRef.current){
            clearTimeout(timeoutRef.current)

            timeoutRef.current = null
        }

        stopAudio(callback)
    }

    const handleClick = () => {
        if(isPlayingRef.current){
            handleStop(endTime => {
                pausedTimeRef.current = endTime
            })
        }else{
            isPlayingRef.current = true
            playAudio(soundbite, canvasRefs.current, pausedTimeRef.current)

            pausedTimeRef.current = 0
        }
    }

    return (
        <div className="slide">
            <div
                className={`card ${soundbite ? 'soundbite' : ''} ${backgroundColour || ''}`}
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleStop}
                onClick={handleClick}
            >
                {backgroundImage &&
                    <div
                        className={'bg'}
                    >
                        <div 
                            className={'bg-inner'}
                            style={{
                                backgroundImage: `url(${backgroundImage.mediaItemUrl})`
                            }}
                        />
                    </div>
                }
                {soundbite &&
                    <>
                        <img className={'play-icon'} src={PlayIcon} />
                        <div className="canvasses">
                            <canvas 
                                ref={ref => canvasRefs.current[0] = ref} 
                            />
                            <canvas 
                                ref={ref => canvasRefs.current[1] = ref} 
                            />
                        </div>
                    </>
                }
                {quote &&
                    <div 
                        className="copy big bold" 
                        dangerouslySetInnerHTML={{ __html: `“${quote}”` }} 
                    />
                }
                {(attribution && attribution.name) ?
                    <div className="attribution">
                        <p className="bold">
                            {attribution.name}
                        </p>
                        <p dangerouslySetInnerHTML={{ __html: attribution.role }} />
                    </div>
                    : null}
            </div>
        </div>
    )
}