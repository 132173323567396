import React from 'react'

export default ({
    copy,
    alignment,
    hashtag
}) => {

    let className = 'wrapper'

    className += ' align-'+alignment
    
    return (
        <div className="container">
            <div className={className}>
                <h2 dangerouslySetInnerHTML={{__html : copy}} />
            </div>
        </div>
    )
}