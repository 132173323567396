import React from 'react'
import Slider from 'components/globals/slider'

export default ({
    title,
    copy,
    images
}) => {
    
    return (
        <div className="container">
            <div className="columns">
                <div className="text-column">
                    <h2 className={'h1'} dangerouslySetInnerHTML={{__html: title}}/>
                    <div className="copy medium" dangerouslySetInnerHTML={{ __html: copy }} />
                </div>
                <div className="image-column">
                    <Slider
                        options={{
                            wrapAround: true,
                            prevNextButtons: false,
                            pageDots: false,
                            adaptiveHeight: false,
                            cellAlign: 'center',
                            fade: true,
                            autoPlay: 3000
                        }}
                    >
                        { images.map(({image}, index) => {
                            return (
                                <div 
                                    className="image"
                                    key={index}
                                >
                                    <div 
                                        className="image-inner"
                                        style={{
                                            backgroundImage: `url(${ image.mediaItemUrl })`
                                        }}
                                    />
                                </div>
                            )
                        })}
                    </Slider>
                </div>
            </div>
        </div>
    )
}