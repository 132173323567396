import React, { useContext, useState } from 'react'
import { MainContext } from 'context/context'

import PlayIconMint from 'images/play-icon-mint.png'

export default ({
    title,
    backgroundVideo,
    video
}) => {
    const {
        openModal
    } = useContext(MainContext)

    return (
        <>
            {backgroundVideo &&
                <div className="video-background">
                    <video preload="auto" data-setup='{}' autoPlay playsInline loop muted>
                        <source src={backgroundVideo.mediaItemUrl} type="video/mp4" />
                    </video>
                </div>
            }
            <div className="overlay">
                <div 
                    className="content"
                >
                    <button onClick={() => {
                        openModal({
                            type: 'VIDEO',
                            data: video
                        })
                    }}>
                        <img src={PlayIconMint} />
                    </button>
                    <h2 
                        dangerouslySetInnerHTML={{ __html: title}}
                    />
                </div>
                <div className="underlay" />
            </div>
        </>
    )
}