import React, { useRef, useEffect } from 'react'
import 'vendor/flickity-as-nav-for/as-nav-for'
import 'flickity/css/flickity.css'
import Slider from 'components/globals/slider'

import SoundBitesCarouselCard from './soundBitesCarouselCard'
import { AudioContext } from 'standardized-audio-context';


export default ({
    backgroundImage,
    items
}) => {
    const loadedAudioItems = useRef({})
    const audioPlayerRef = useRef(null)
    const sliderRef = useRef(null)
    const globalIsPlaying = useRef(false)
    const hasTriggeredLoadRef = useRef(false)
    const audioContext = useRef(null)

    useEffect(() => {
        document.addEventListener('click', unlockAudio)
        document.addEventListener('touchstart', unlockAudio)

        return () => {
            document.removeEventListener('click', unlockAudio)
            document.removeEventListener('touchstart', unlockAudio)
        }
    }, [])

    const unlockAudio = _ => {
        if (hasTriggeredLoadRef.current) {
            return
        }

        hasTriggeredLoadRef.current = true

        const _loadedAudioItems = {}

        items.forEach((item, index) => {
            const { soundbite } = item

            if (soundbite && soundbite.databaseId) {
                const _audio = new Audio(soundbite.mediaItemUrl)
                _audio.crossOrigin = "anonymous"

                if (!index) {
                    _audio.play()

                    setTimeout(() => {
                        _audio.pause()
                        _audio.currentTime = 0
                    }, 1)
                } else {
                    _audio.load()
                }

                _loadedAudioItems[soundbite.databaseId] = _audio
            }
        })

        loadedAudioItems.current = _loadedAudioItems
    }

    const getAudioContext = () => {
        if (typeof window === 'undefined') {
            return null
        }

        if(!audioContext.current){
            const audioContextConstructor = AudioContext // window.AudioContext || window.webkitAudioContext || null
            const context = new audioContextConstructor()

            audioContext.current = context
        }

        return audioContext.current
    }

    const playAudio = (soundbite, refs, startTime) => {
        if (!soundbite || !soundbite.databaseId) {
            return
        }

        globalIsPlaying.current = true

        audioPlayerRef.current = loadedAudioItems.current[soundbite.databaseId]

        if (!audioPlayerRef.current) {
            return
        }

        const audio = audioPlayerRef.current

        const context = getAudioContext()

        if (!context) {
            return
        }

        const src = context.createMediaElementSource(audio)

        const analyser = context.createAnalyser()

        const canvasesWithContexts = refs.map(ref => {
            return {
                canvas: ref,
                ctx: ref.getContext('2d')
            }
        })

        const canvasSize = canvasesWithContexts[0].canvas.getBoundingClientRect()

        canvasesWithContexts.forEach(_canvas => {
            _canvas.canvas.width = canvasSize.width
            _canvas.canvas.height = canvasSize.height
        })

        let isPlaying = true

        const handleEnded = () => {
            isPlaying = false
        }

        audio.addEventListener('ended', handleEnded)

        src.connect(analyser)
        analyser.connect(context.destination)

        analyser.fftSize = 256

        const bufferLength = analyser.frequencyBinCount
        const dataArray = new Uint8Array(bufferLength)

        const WIDTH = canvasesWithContexts[0].canvas.width
        const HEIGHT = canvasesWithContexts[0].canvas.height

        let barWidth = (WIDTH / bufferLength) * 1
        let barHeight
        let x = 0

        const getNormalisedBarHeight = value => {
            return ((value / 255) * HEIGHT)
        }

        const renderFrame = () => {
            if (isPlaying && globalIsPlaying.current) {
                requestAnimationFrame(renderFrame)
            } else {
                audio.removeEventListener('ended', handleEnded)
            }

            canvasesWithContexts.forEach(_canvas => {
                const {
                    ctx,
                    canvas
                } = _canvas

                // Clear the canvas
                ctx.clearRect(0, 0, canvas.width, canvas.height)
                var w = canvas.width
                canvas.width = 1
                canvas.width = w

                x = 0

                ctx.fillStyle = "transparent"
                ctx.fillRect(0, 0, WIDTH, HEIGHT)

                for (let i = 0; i < bufferLength; i++) {
                    barHeight = getNormalisedBarHeight(dataArray[i])

                    ctx.fillStyle = 'white'
                    ctx.fillRect(x, HEIGHT - barHeight, barWidth, barHeight)

                    x += barWidth + 1
                }
            })

            analyser.getByteFrequencyData(dataArray)
        }

        audio.load()
        audio.currentTime = startTime || 0

        audio.play()
        renderFrame()

        loadedAudioItems.current[soundbite.databaseId] = loadedAudioItems.current[soundbite.databaseId].cloneNode()
    }

    const stopAudio = callback => {
        if (audioPlayerRef.current) {
            audioPlayerRef.current.pause()

            if (typeof callback === 'function') {
                callback(audioPlayerRef.current.currentTime)
            }

            globalIsPlaying.current = false
        }
    }

    const resumeAudio = () => {
        if (audioPlayerRef.current) {
            audioPlayerRef.current.play()
        }
    }

    const handleHover = type => {
        if (type === 'out' && sliderRef.current) {
            sliderRef.current.player.play()
        }
    }

    return (
        <>
            {backgroundImage &&
                <div className="bg-container">
                    <img src={backgroundImage.mediaItemUrl} />
                </div>
            }
            <div className="slider-container" id={'realTalk'}>
                <div
                    className="slides"
                    onMouseEnter={_ => handleHover('in')}
                    onMouseLeave={_ => handleHover('out')}
                >
                    {typeof window != 'undefined' &&
                        <Slider
                            className={'carousel'}
                            options={{
                                prevNextButtons: false,
                                pageDots: false,
                                autoPlay: 3000,
                                infinite: true,
                                initalIndex: 5,
                                wrapAround: true,
                                selectedAttraction: 0.02,
                                friction: 0.4,
                                cellAlign: 'center'
                            }}
                            flickityRef={ref => sliderRef.current = ref}
                        >
                            {items.map((item, index) => (
                                <SoundBitesCarouselCard
                                    item={item}
                                    playAudio={playAudio}
                                    stopAudio={stopAudio}
                                    resumeAudio={resumeAudio}
                                    key={index}
                                />
                            ))}
                        </Slider>
                    }
                </div>
            </div>
        </>
    )
}