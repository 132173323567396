import React, { useEffect, useRef, useState, useContext } from 'react'
import { isEqual, throttle } from 'lodash'
import { MainContext } from "context/context"

import Hero from 'components/flexibleContent/hero'
import SimpleHero from 'components/flexibleContent/simpleHero'
import BigParagraph from 'components/flexibleContent/bigParagraph'
import TitleCopy from 'components/flexibleContent/titleCopy'
import SoundbitesCarousel from 'components/flexibleContent/soundbitesCarousel'
import StatsGrid from 'components/flexibleContent/statsGrid'
import SimpleImageWithCopy from 'components/flexibleContent/simpleImageWithCopy'
import SimplePointsGrid from 'components/flexibleContent/simplePointsGrid'
import VideoHero from 'components/flexibleContent/videoHero'
import BlogCarousel from 'components/flexibleContent/blogCarousel'
import AlternatingTeamsGrid from 'components/flexibleContent/alternatingTeamsGrid'

const components = {
    Hero,
    SimpleHero,
    BigParagraph,
    TitleCopy,
    SoundbitesCarousel,
    StatsGrid,
    SimpleImageWithCopy,
    SimplePointsGrid,
    VideoHero,
    BlogCarousel,
    AlternatingTeamsGrid
}

export default ({ 
    content,
    slug = ''
}) => {

    const {
		componentRefs
    } = useContext(MainContext)
    
    const [visibleIndices, setVisibleIndices] = useState([])

    const visibleIndicesRef = useRef(visibleIndices)

    visibleIndicesRef.current = visibleIndices

    const handleScroll = () => {
        let _visibleIndices = []

        componentRefs.current.forEach((componentRef, index) => {
            const offset = componentRef.getBoundingClientRect().top

            let windowHeight = null
            
            if (typeof window != 'undefined') {
                windowHeight = window.innerHeight
            }

            if(offset < (windowHeight / 3 * 2)) {
                _visibleIndices.push(index) 
            }
        })

        if(!isEqual(_visibleIndices,visibleIndicesRef.current)) {
            setVisibleIndices(_visibleIndices)
        }
    }

    const debouncedHandleScroll = useRef(throttle(handleScroll, 50))

    useEffect(() => {
        const { current } = debouncedHandleScroll

        window.addEventListener('scroll', current)

        setTimeout(() => {
            current()
        },500)

        return () => window.removeEventListener('scroll', current)
    }, [])

    const addComponent = (ref, index) => {
        if(ref){
            const _componentRefs =  [...componentRefs.current]

            _componentRefs[index] = ref

            componentRefs.current = _componentRefs
        }
    }

    return (
        <div className={slug}>
            { content.flexibleContent.map((row, index) => {
                if(!row){
                    return null
                }
                
                const Component = row.__typename.replace('WPGraphQL_Page_Flexiblecontent_FlexibleContent_','')
                const Tag = components[Component]

                const isScrolledIntoView = visibleIndices && visibleIndices.indexOf(index) !== -1

                let className = `${Component} ${ isScrolledIntoView ? 'scrolled-into-view' : '' }`

                return (
                    <section 
                        className={className} 
                        ref={ref => addComponent(ref, index)} 
                        key={index}
                        id={Component}
                    >
                        <Tag 
                            {...row} 
                            isScrolledIntoView={isScrolledIntoView}
                        />
                    </section>
                )
            }) }
        </div>
    )
}

