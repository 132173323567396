import React, { 
    useEffect,
    useState,
} from 'react'

export default ({
   heading,
   backgroundImage
}) => {
    const [hasMounted, setHasMounted] = useState(false)

    useEffect(() => {
        setTimeout(() => {
            setHasMounted(true)
        },300)
    }, [])

    return (
        <div 
            className={`jarallax`} 
        >
            { backgroundImage ?
                <img src={backgroundImage.mediaItemUrl} className={'bg'} alt={ heading } />
            : null }
            <div className={`hero ${ hasMounted ? 'mounted' : '' }`}>

                <div className="container">
                    <div className="content">
                        <h2
                            dangerouslySetInnerHTML={{ __html: heading}}
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}
