import React, {
    useRef,
    useEffect,
    useState,
    useContext
} from 'react'

import { MainContext } from "context/context"
import Button from 'components/globals/button'
import PlayIcon from 'images/play-icon.png'

export default ({
    heading,
    copy,
    video,
    backgroundVideo
}) => {
    const {
        addInvertedHeaderSection,
        openModal
    } = useContext(MainContext)

    const [hasMounted, setHasMounted] = useState(false)

    const elementRef = useRef(null)

    useEffect(() => {
        if (elementRef && elementRef.current) {
            addInvertedHeaderSection(elementRef.current)
        }
    }, [elementRef])

    useEffect(() => {
        setTimeout(() => {
            setHasMounted(true)
        }, 300)
    }, [])

    return (
        <div
            className={`jarallax`}
            ref={ref => elementRef.current = ref}
        >
            <div className={`hero ${hasMounted ? 'mounted' : ''}`}>
                {backgroundVideo &&
                    <div className="video-background">
                        <video preload="auto" data-setup='{}' autoPlay playsInline loop muted>
                            <source src={backgroundVideo.mediaItemUrl} type="video/mp4" />
                        </video>
                    </div>
                }
                <div className="container">
                    <div className="content">
                        <h1
                            dangerouslySetInnerHTML={{ __html: heading }}
                        />
                        <p className="copy">
                            {copy}
                        </p>
                        {video &&
                            <div className="button-row">
                                <Button
                                    label={'Watch video'}
                                    icon={PlayIcon}
                                    iconPosition={'right'}
                                    onClick={() => openModal({
                                        type: 'VIDEO',
                                        data: video
                                    })}
                                />
                            </div>
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}
